import Accounts from "./pages/Accounts";
import Dashboard from "./pages/Dashboard";
import Inventory from "./pages/Inventory";
import Invoices from "./pages/Invoices";
import Variety from "./pages/Variety";

import {
  DatabaseOutlined,
  DashboardOutlined,
  FunnelPlotOutlined,
  ShoppingCartOutlined,
  TransactionOutlined,
} from "@ant-design/icons";

const ROUTES = [
  {
    name: "dashboard",
    path: "/dashboard",
    exact: true,
    title: "Dashboard",
    component: Dashboard,
    sidebar: true,
    icon: DashboardOutlined,
  },
  {
    name: "accounts",
    path: "/Accounts",
    title: "Accounts",
    component: Accounts,
    sidebar: true,
    icon: DatabaseOutlined,
  },
  {
    name: "variety",
    path: "/variety",
    title: "Variety",
    component: Variety,
    sidebar: true,
    icon: FunnelPlotOutlined,
  },
  {
    name: "inventory",
    path: "/inventory",
    title: "Inventory",
    component: Inventory,
    sidebar: true,
    icon: ShoppingCartOutlined,
  },
  {
    name: "invoices",
    path: "/invoices",
    title: "Invoices",
    component: Invoices,
    sidebar: true,
    icon: TransactionOutlined,
  },
  {
    name: "profile",
    path: "/profile",
    exact: true,
    title: "Profile",
    component: Dashboard,
    sidebar: false,
  },
];

export default ROUTES;
