import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  message,
  Button,
  Space,
  Empty,
  Modal,
} from "antd";
import {
  PlusOutlined,
  ExportOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import withLayoout from "../../hocs/withLayoout";
import axios from "../../utils/axios";
import AddInventoryPopup from "../../components/AddInventoryPopup";
import FilterNames from "../../components/FilterNames";
import "./styles.scss";
import moment from "moment-timezone";
import { API_URL } from "../../constants.js";
import ls from "../../utils/localstorage";

const { confirm } = Modal;

function Inventory() {
  const { Title, Text } = Typography;
  const [inventory, setInventory] = useState([]);
  const [filteredInventory, setFilteredInventory] = useState();
  const [addPopupVisibility, setAddPopupVisibility] = useState(false);
  const [singleInventory, setSingleInventory] = useState(null);
  const [filtersAdded, setFiltersAdded] = useState();

  const getInventory = async () => {
    try {
      const response = await axios.get(`/inventory`);
      setInventory(response?.data);
      console.log({ response: response.data });
    } catch (error) {
      console.log({ error });
      message.error("Couldn't fetch Inventory!");
    }
  };

  useEffect(() => {
    (async () => await getInventory())();
    return () => {
      setInventory(null);
    };
  }, [singleInventory]);

  const handleExport = async () => {
    try {
      const token = ls.get("user")?.accessToken;
      const response = await fetch(
        API_URL +
          `/inventory?isExport=true&${filtersAdded?.name}=${filtersAdded?.selectedFilters}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      const blob = await response.blob();
      console.log({ response, blob });

      if (response?.ok) {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `inventory-${moment()}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        message.success("Inventory Exported Successfully!");
      }
    } catch (error) {
      console.log({ error });
      message.error("Could process not export request");
    }
  };

  const handleClose = async (value) => {
    await getInventory();
    setAddPopupVisibility(value);
  };

  const handleAddInventory = () => {
    setAddPopupVisibility((s) => !s);
  };

  const handleEdit = (inventory) => {
    setSingleInventory(inventory);
    setAddPopupVisibility((s) => !s);
  };
  const handleDelete = (inventory) => {
    confirm({
      title: "Are you sure you want to delete this inventory?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await axios.delete(`/inventory/${inventory._id}`);
          message.success("Inventory deleted successfully!");
          setSingleInventory(null);
        } catch (error) {
          message.error("Failed to delete the inventory");
          console.log({ error });
        }
      },
    });
  };
  return (
    <>
      <div className="layout-content top-p">
        <Row gutter={[32, 0]}>
          <div className="button-container">
            <Space direction="horizontal" size={14}>
              <Text strong>Total Bori: </Text>
              <div> {filteredInventory?.bori || inventory?.bori || 0}</div>

              <Text strong>Total Tora : </Text>
              <div> {filteredInventory?.tora || inventory?.tora || 0}</div>

              <Text strong>Total Tora 25 : </Text>
              <div>{filteredInventory?.tora25 || inventory?.tora25 || 0} </div>

              <Text strong>Total Tora 50 : </Text>
              <div>{filteredInventory?.tora50 || inventory?.tora50 || 0} </div>

              <Text strong>Total Weight : </Text>
              <div>{filteredInventory?.weight || inventory?.weight || 0} </div>

              <Button
                onClick={handleAddInventory}
                type="primary"
                icon={<PlusOutlined />}
              >
                Add
              </Button>
              <Button
                onClick={handleExport}
                type="primary"
                icon={<ExportOutlined />}
                className="mr-5"
              >
                Export
              </Button>
            </Space>
          </div>
          <Col xs={24} sm={24} md={24} lg={24} xl={32} className="mb-32">
            <Card bordered={false} className="criclebox cardbody h-full">
              <div className="project-ant">
                <div className="pl-24">
                  <Title level={1}>Inventory</Title>
                </div>
                <div>
                  <Row className="mr-10" gutter={[5, 0]}>
                    <div className="ant-filtertabs">
                      <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                        <Space direction="horizontal" size={14}>
                          <Text strong>Filter:</Text>
                          <FilterNames
                            data={inventory.inventory}
                            setData={setFilteredInventory}
                            isInventory={true}
                            names="varieties"
                            setFiltersAdded={setFiltersAdded}
                          />
                        </Space>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
              <div className="ant-list-box table-responsive">
                <table className="width-100">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Variety</th>
                      <th>Invoice Number</th>
                      <th>Description</th>
                      <th>Added by</th>
                      <th>Tora50</th>
                      <th>Tora25</th>
                      <th>Tora</th>
                      <th>Bori</th>
                      <th>Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredInventory
                      ? filteredInventory?.inventory?.map((d, index) => (
                          <tr key={index}>
                            <td>
                              {moment(d.date)
                                .local()
                                .format("YYYY-MM-DD HH:mm:ss")}
                            </td>
                            <td>
                              <span className="text-xs font-weight-bold">
                                {d.variety}
                              </span>
                            </td>

                            <td>
                              <span>{d.invoiceNumber}</span>
                            </td>
                            <td>
                              <span>{d.description}</span>
                            </td>
                            <td>
                              <div>{d.addedBy}</div>
                            </td>
                            <td>
                              <div>{d.tora50}</div>
                            </td>
                            <td>
                              <div>{d.tora25}</div>
                            </td>
                            <td>
                              <div>{d.tora}</div>
                            </td>

                            <td>
                              <div>{d?.bori}</div>
                            </td>
                            <td>
                              <div>{d.weight} </div>
                            </td>
                            <td className="d-flex">
                              <Button
                                type="link"
                                icon={<EditOutlined />}
                                onClick={() => handleEdit(d)}
                                style={{ marginRight: 8 }}
                                size="small"
                              />

                              <Button
                                type="link"
                                danger
                                icon={<DeleteOutlined />}
                                onClick={() => handleDelete(d)}
                                size="small"
                              />
                            </td>
                          </tr>
                        ))
                      : inventory?.inventory?.map((d, index) => (
                          <tr key={index}>
                            <td>
                              {moment(d.date)
                                .local()
                                .format("YYYY-MM-DD HH:mm:ss")}
                            </td>
                            <td>
                              <span className="text-xs font-weight-bold">
                                {d.variety}
                              </span>
                            </td>
                            <td>
                              <span>{d.invoiceNumber}</span>
                            </td>
                            <td>
                              <div>{d.description}</div>
                            </td>
                            <td>
                              <div>{d.addedBy}</div>
                            </td>
                            <td>
                              <div>{d.tora50}</div>
                            </td>
                            <td>
                              <div>{d.tora25}</div>
                            </td>
                            <td>
                              <div>{d.tora}</div>
                            </td>

                            <td>
                              <div>{d?.bori}</div>
                            </td>
                            <td>
                              <div>{d.weight} </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                {!inventory.inventory?.length && (
                  <Empty className="p-3" description={"No Results "} />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <AddInventoryPopup
        visible={addPopupVisibility}
        onClose={handleClose}
        closePopup={setAddPopupVisibility}
        inventory={singleInventory}
        setInventory={setSingleInventory}
      />
    </>
  );
}

export default withLayoout(Inventory);
