import React, { useEffect } from "react";
import { Modal, Button, Input, Form, message, Checkbox } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import axios from "../../utils/axios.js";

const AddAccountPopup = ({
  visible,
  onClose,
  closePopup,
  account,
  setAccounts,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (account) {
      form.setFieldsValue({
        ...account,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, account]);

  const handleFinish = async (values) => {

    try {
      if (account) {
        delete values.name;
        await axios.patch(`/account/${account._id}`, values);
        message.success("Account Updated Successfully!");
      } else {
        await axios.post("/account", values);
        message.success("Account Added Successfully!");
      }

      onClose(false);
      form.resetFields();

      setAccounts(null);
    } catch (error) {
      message.error("Couldn't process the account");
      console.log({ error });
    }
  };

  const handleFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Please check the form fields and try again.");
  };

  return (
    <Modal
      open={visible}
      title={account ? "Edit Account" : "Add Account"}
      onCancel={() => {closePopup(false);form.resetFields();setAccounts(null);}}
      footer={null}
      closeIcon={<CloseOutlined />}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please enter a Name!" }]}
        >
          {account? <Input disabled placeholder="Enter Name" rows={4} /> : <Input placeholder="Enter Name" rows={4} />}
          
        </Form.Item>

        <Form.Item
          name="cnic"
          label="CNIC"
          rules={[
            { required: true, message: "Please enter CNIC!" },
            { pattern: /^\d+$/, message: "Amount must be a number!" },
          ]}
        >
          <Input placeholder="Enter CNIC" />
        </Form.Item>

        <Form.Item
          name="mobile"
          label="Contact"
          rules={[
            { required: true, message: "Please enter contact!" },
            {
              pattern: /^(0|\+92)?3\d{9}$/,
              message: "Please enter a valid mobile number!",
            },
          ]}
        >
          <Input placeholder="Enter contact" />
        </Form.Item>

        <Form.Item
        name="isPersonal"
        valuePropName="checked"
        initialValue={false} // Default value of checkbox
      >
        <Checkbox>
          Personal
        </Checkbox>
      </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            {account ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddAccountPopup;
