import slugify from "slugify";

export const toSlug = (str) => {
  return slugify(str).toLowerCase();
};

export const addCommasToAmount = (amount = "") => {
  if (typeof amount !== "string") {
    amount = amount.toString();
  }
  return amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const dataMapping = {
  variety: "variety",
  account: "account",
};
