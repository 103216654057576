import React, { useEffect } from "react";
import { Modal, Button, Input, Form, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import axios from "../../utils/axios.js";

const AddVarietyPopup = ({
  visible,
  onClose,
  closePopup,
  variety,
  setVariety,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (variety) {
      form.setFieldsValue({
        ...variety,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, variety]);

  const handleFinish = async (values) => {
    try {
      if (variety) {
        await axios.patch(`/variety/${variety._id}`, values);
        message.success("Variety Updated Successfully!");
      } else {
        await axios.post("/variety", values);
        message.success("Variety Added Successfully!");
      }

      onClose(false);
      form.resetFields();

      setVariety(null);
    } catch (error) {
      message.error("Couldn't process the variety");
      console.log({ error });
    }
  };

  const handleFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Please check the form fields and try again.");
  };

  const validateFloatNumber = (_, value) => {
    if (value === undefined) {
      return;
    }

    if (+value === parseFloat(Number(value).toFixed(2))) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Please enter a valid weight"));
  };

  return (
    <Modal
      open={visible}
      title={variety ? "Edit Variety" : "Add Variety"}
      onCancel={() => closePopup(false)}
      footer={null}
      closeIcon={<CloseOutlined />}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: "Please enter a Name!" }]}
        >
          <Input placeholder="Enter Name" rows={4} />
        </Form.Item>

        <Form.Item
          name="tora50"
          label="Tora 50"
          rules={[{ pattern: /^-?\d+$/, message: "Tora 50 must be a number!" }]}
        >
          <Input placeholder="Enter Tora 50" />
        </Form.Item>
        <Form.Item
          name="tora25"
          label="Tora 25"
          rules={[{ pattern: /^-?\d+$/, message: "Tora 25 must be a number!" }]}
        >
          <Input placeholder="Enter Tora 25" />
        </Form.Item>
        <Form.Item
          name="bori"
          label="Bori"
          rules={[{ pattern: /^-?\d+$/, message: "Bori must be a number!" }]}
        >
          <Input placeholder="Enter Bori" />
        </Form.Item>
        <Form.Item
          name="weight"
          label="Weight"
          rules={[{ validator: validateFloatNumber }]}
        >
          <Input placeholder="Enter Weight" step="0.01" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            {variety ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddVarietyPopup;
