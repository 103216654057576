/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dropdown, Menu, Checkbox, Button, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import axios from "../../utils/axios";
import "./styles.scss";

const DropdownWithCheckbox = ({
  data,
  setData,
  names,
  isInvoices,
  isVariety,
  isInventory,
  isTransaction,
  setFiltersAdded,
  filterAdded,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [visible, setVisible] = useState(false);
  const [menuItems, setMenuItems] = useState();

  useEffect(() => {
    (async () => {
      try {
        let response = null;
        if ((isInvoices || isInventory) && names === "varieties") {
          response = await axios.get("/variety/getVarities");
        } else if (isInvoices && names === "accountNames") {
          response = await axios.get("/account/getAccounts");
        } else if (isTransaction && names === "account") {
          response = await axios.get("/account/getAllAccounts");
        } else if (isTransaction && names === "accountNames") {
          response = await axios.get("/account/getAccounts");
        }
        setMenuItems(response?.data);
      } catch (e) {
        message.error("Unable to fetch filters data");
      }
    })();
  }, [filterAdded]);

  const handleMenuClick = ({ key }) => {
    const itemIndex = selectedItems.indexOf(key?.name);
    if (itemIndex === -1) {
      setSelectedItems([...selectedItems, key?.name]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item !== key?.name));
    }
  };

  const handleOkClick = async () => {
    let response = null;
    let selectedString = selectedItems?.join(",");
    let queryString;
    if (filterAdded?.name !== names) {
      queryString = filterAdded?.name
        ? `&${filterAdded?.name}=${filterAdded?.selectedFilters}`
        : "";
    }
    try {
      if (isInvoices) {
        response = await axios.get(
          `/invoice?${names}=${selectedString}${queryString}`
        );
      } else if (isInventory) {
        response = await axios.get(
          `/inventory?${names}=${selectedString}${queryString}`
        );
      } else if (isTransaction && names === 'accountNames') {
        response = await axios.get(
          `/transaction?${names}=${selectedString}${queryString}`
        );
      } else if (isTransaction) {
        response = await axios.get(
          `/invoice?${names}=${selectedString}${queryString}`
        );
      }

      if (response?.data) {
        if (filterAdded?.name !== names) {
          setFiltersAdded({ name: names, selectedFilters: selectedString });
        }

        setData(response.data);
      }
    } catch (e) {
      message.error("Error while processing filters");
    }
    setVisible(false);
  };

  const handleSelectAll = () => {
    const allItemKeys = menuItems?.map((item) => item?.name);
    setSelectedItems(allItemKeys);
  };

  const handleDeselectAll = () => {
    setSelectedItems([]);
  };

  const camelCaseToEnglish = (camelCaseStr) => {
    const result = camelCaseStr
      ?.replace(/([a-z])([A-Z])/g, "$1 $2")
      ?.replace(/^./, (match) => match.toUpperCase());
    return result;
  };
  const menu = (
    <Menu
      style={{
        padding: "4px",
      }}
      className="custom-menu"
    >
      <div className="select-container">
        <Menu.Item key="select-all">
          <Button onClick={handleSelectAll} type="link">
            Select All
          </Button>
        </Menu.Item>
        <Menu.Item key="deselect-all">
          <Button onClick={handleDeselectAll} type="link">
            Clear Filter
          </Button>
        </Menu.Item>
      </div>
      <div style={{ maxHeight: "200px", overflowY: "auto" }}>
        {menuItems &&
          menuItems?.map((item) => (
            <Menu.Item key={item?._id}>
              <Checkbox
                checked={selectedItems.includes(item?.name)}
                onChange={() => handleMenuClick({ key: item })}
              >
                {item.name || item.variety}
              </Checkbox>
            </Menu.Item>
          ))}
      </div>
      <Menu.Item key="ok">
        <Button type="primary" onClick={handleOkClick}>
          OK
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      open={visible}
      onOpenChange={setVisible}
      trigger={["click"]}
    >
      <Button className="menu-button" icon={<DownOutlined />}>
        Select {camelCaseToEnglish(names)}
      </Button>
    </Dropdown>
  );
};

export default DropdownWithCheckbox;
