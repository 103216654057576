import { useEffect, useState } from "react";
import moment from "moment-timezone";
import {
  Card,
  Col,
  Row,
  Typography,
  message,
  Button,
  Space,
  Empty,
  Modal,
  Radio,
} from "antd";
import {
  PlusOutlined,
  ExportOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import withLayoout from "../../hocs/withLayoout";
import axios from "../../utils/axios";
import AddAccountPopup from "../../components/AddAccountPopup";
import { addCommasToAmount } from "../../utils/common";
import "./styles.scss";
import { API_URL } from "../../constants.js";
import ls from "../../utils/localstorage";
const { confirm } = Modal;

function Accounts() {
  const { Title, Text } = Typography;
  const [accounts, setAccounts] = useState([]);
  const [addPopupVisibility, setAddPopupVisibility] = useState(false);
  const [singleAccount, setSingleAccount] = useState(null);
  const [accountType, setAccountType] = useState("public");

  const getAccounts = async () => {
    try {
      let queryString = `type=${accountType}`;
      const response = await axios.get(`/account?${queryString}`);
      setAccounts(response?.data);
    } catch (error) {
      console.log({ error });
      message.error("Couldn't fetch Accounts!");
    }
  };

  useEffect(() => {
    (async () => await getAccounts())();
    return () => {
      setAccounts(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleAccount, accountType]);

  const handleExport = async () => {
    let queryString = `&type=${accountType}`;
    try {
      const token = ls.get("user")?.accessToken;
      const response = await fetch(
        API_URL + `/account?isExport=true${queryString}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      const blob = await response.blob();
      console.log({ response, blob });

      if (response?.ok) {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `accounts-${moment()}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        message.success("Accounts Exported Successfully!");
      }
    } catch (error) {
      console.log({ error });
      message.error("Could process not export request");
    }
  };

  const handleClose = async (value) => {
    await getAccounts();
    setAddPopupVisibility(value);
  };

  const handleAddAccount = () => {
    setAddPopupVisibility((s) => !s);
  };

  const handleEdit = (account) => {
    setSingleAccount(account);
    setAddPopupVisibility((s) => !s);
  };
  const handleDelete = (account) => {
    confirm({
      title: "Are you sure you want to delete this account?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await axios.delete(`/account/${account._id}`);
          message.success("Account deleted successfully!");
          setSingleAccount(null);
        } catch (error) {
          message.error("Failed to delete the account");
          console.log({ error });
        }
      },
    });
  };

  const handleRadioChange = (e) => {
    const selectedValue = e.target.value;
    setAccountType(selectedValue);
  };

  return (
    <>
      <div className="layout-content top-p">
        <Row gutter={[32, 0]}>
          <div className="button-container">
            <Space direction="horizontal" size={14}>
              <Text strong>Difference: </Text>
              <div> {addCommasToAmount(accounts?.difference) || 0} PKR</div>
              <Text strong>Total Payable : </Text>
              <div> {addCommasToAmount(accounts?.payable) || 0} PKR</div>
              <Text strong>Total Receiveable : </Text>
              <div>{addCommasToAmount(accounts?.recivable) || 0} PKR</div>
              <Button
                onClick={handleAddAccount}
                type="primary"
                icon={<PlusOutlined />}
              >
                Add
              </Button>
              <Button
                onClick={handleExport}
                type="primary"
                icon={<ExportOutlined />}
                className="mr-5"
              >
                Export
              </Button>
            </Space>
          </div>
          <Col xs={24} sm={24} md={24} lg={24} xl={32} className="mb-32">
            <Card bordered={false} className="criclebox cardbody h-full">
              <div className="project-ant">
                <div className="pl-24">
                  <Title level={1}>Accounts</Title>
                </div>
                <Row className="mr-10" gutter={[5, 0]}>
                  <div className="ant-filtertabs">
                    <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                      <Space direction="horizontal" size={14}>
                        <Text strong>Account Type:</Text>
                        <Radio.Group
                          onChange={handleRadioChange}
                          value={accountType}
                          defaultValue="public"
                        >
                          <Radio value="">All</Radio>
                          <Radio value="private">Confidential</Radio>
                          <Radio value="public">Public</Radio>
                        </Radio.Group>
                      </Space>
                    </div>
                  </div>
                </Row>{" "}
              </div>
              <div className="ant-list-box table-responsive">
                <table className="width-100">
                  <thead>
                    <tr>
                      <th>Created Date</th>
                      <th>Name</th>
                      <th>Contact</th>
                      <th>CNIC</th>
                      <th>Amount</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {accounts?.accounts?.map((d, index) => (
                      <tr key={index}>
                        <td>
                          {moment(d.date).local().format("YYYY-MM-DD HH:mm:ss")}
                        </td>
                        <td>
                          <span className="text-xs font-weight-bold">
                            {d.name}
                          </span>
                        </td>
                        <td>
                          <div>{d.mobile}</div>
                        </td>

                        <td>
                          <div>{d?.cnic}</div>
                        </td>
                        <td>
                          <div>
                            {addCommasToAmount(d.amount)}
                            PKR
                          </div>
                        </td>
                        <td className="d-flex">
                          <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => handleEdit(d)}
                            style={{ marginRight: 8 }}
                            size="small"
                          />

                          <Button
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => handleDelete(d)}
                            size="small"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!accounts?.accounts?.length && (
                  <Empty
                    className="p-3"
                    description={"No Results within the given date range"}
                  />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <AddAccountPopup
        visible={addPopupVisibility}
        onClose={handleClose}
        closePopup={setAddPopupVisibility}
        account={singleAccount}
        setAccounts={setSingleAccount}
      />
    </>
  );
}

export default withLayoout(Accounts);
