import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Typography,
  message,
  Button,
  Space,
  Empty,
  Modal,
} from "antd";
import {
  PlusOutlined,
  ExportOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import withLayoout from "../../hocs/withLayoout";
import axios from "../../utils/axios";
import AddVarietyPopup from "../../components/AddVarietyPopup";
import "./styles.scss";
import { API_URL } from "../../constants";
import ls from "../../utils/localstorage";
import moment from "moment-timezone";

const { confirm } = Modal;

function Variety() {
  const { Title } = Typography;
  const [variety, setVariety] = useState([]);

  const [addPopupVisibility, setAddPopupVisibility] = useState(false);
  const [singleVariety, setSingleVariety] = useState(null);

  const getVariety = async () => {
    try {
      const response = await axios.get(`/variety`);
      setVariety(response?.data);
      console.log({ response: response.data });
    } catch (error) {
      console.log({ error });
      message.error("Couldn't fetch Variety!");
    }
  };

  useEffect(() => {
    (async () => await getVariety())();
    return () => {
      setVariety(null);
    };
  }, [singleVariety]);

  const handleExport = async () => {
    try {
      const token = ls.get("user")?.accessToken;
      const response = await fetch(API_URL + `/variety?isExport=true`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

      const blob = await response.blob();
      console.log({ response, blob });

      if (response?.ok) {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `varieties-${moment()}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        message.success("Varieties Exported Successfully!");
      }
    } catch (error) {
      console.log({ error });
      message.error("Could process not export request");
    }
  };

  const handleClose = async (value) => {
    await getVariety();
    setAddPopupVisibility(value);
  };

  const handleAddVariety = () => {
    setAddPopupVisibility((s) => !s);
  };

  const handleDelete = (variety) => {
    confirm({
      title: "Are you sure you want to delete this variety?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await axios.delete(`/variety/${variety._id}`);
          message.success("Variety deleted successfully!");
          setSingleVariety(null);
        } catch (error) {
          message.error("Failed to delete the variety");
          console.log({ error });
        }
      },
    });
  };

  return (
    <>
      <div className="layout-content top-p">
        <Row gutter={[32, 0]}>
          <div className="button-container">
            <Space direction="horizontal" size={14}>
              <Button
                onClick={handleAddVariety}
                type="primary"
                icon={<PlusOutlined />}
              >
                Add
              </Button>
              <Button
                onClick={handleExport}
                type="primary"
                icon={<ExportOutlined />}
                className="mr-5"
              >
                Export
              </Button>
            </Space>
          </div>
          <Col xs={24} sm={24} md={24} lg={24} xl={32} className="mb-32">
            <Card bordered={false} className="criclebox cardbody h-full">
              <div className="project-ant">
                <div className="pl-24">
                  <Title level={1}>Variety</Title>
                </div>
              </div>
              <div className="ant-list-box table-responsive">
                <table className="width-100">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Added by</th>
                      <th>Tora50</th>
                      <th>Tora25</th>
                      <th>Tora</th>
                      <th>Bori</th>
                      <th>Weight</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {variety.map((d, index) => (
                      <tr key={index}>
                        <td>
                          <span className="text-xs font-weight-bold">
                            {d.name}
                          </span>
                        </td>
                        <td>
                          <div>{d.addedBy}</div>
                        </td>
                        <td>
                          <div>{d.tora50}</div>
                        </td>
                        <td>
                          <div>{d.tora25}</div>
                        </td>
                        <td>
                          <div>{d.tora}</div>
                        </td>

                        <td>
                          <div>{d?.bori}</div>
                        </td>
                        <td>
                          <div>{d.weight} </div>
                        </td>
                        <td className="d-flex">
                          <Button
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => handleDelete(d)}
                            size="small"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!variety?.length && (
                  <Empty className="p-3" description={"No Results "} />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <AddVarietyPopup
        visible={addPopupVisibility}
        onClose={handleClose}
        closePopup={setAddPopupVisibility}
        variety={singleVariety}
        setVariety={setSingleVariety}
      />
    </>
  );
}

export default withLayoout(Variety);
