import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Form, message, Select } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import axios from "../../utils/axios.js";
const { TextArea } = Input;
const { Option } = Select;

const AddInventoryPopup = ({
  visible,
  onClose,
  closePopup,
  inventory,
  setInventory,
}) => {
  const [form] = Form.useForm();
  const [varietiesResponse, setVarietiesResponse] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const varietiesResponse = await axios.get("/variety/getVarities");

        setVarietiesResponse(varietiesResponse?.data);
      } catch (error) {
        new Error("Error getting varieties!");
        console.log({ error });
      }
    })();
    if (inventory) {
      form.setFieldsValue({
        ...inventory,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, inventory]);

  const handleFinish = async (values) => {
    try {
      if (inventory) {
        await axios.patch(`/inventory/${inventory._id}`, values);
        message.success("Inventory Updated Successfully!");
      } else {
        await axios.post("/inventory", values);
        message.success("Inventory Added Successfully!");
      }

      onClose(false);
      form.resetFields();

      setInventory(null);
    } catch (error) {
      message.error("Couldn't process the inventory");
      console.log({ error });
    }
  };

  const handleFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.error("Please check the form fields and try again.");
  };

  const validateFloatNumber = (_, value) => {
    if (value === undefined) {
      return Promise.reject(new Error("Please input the weight!"));
    }

    if (+value === parseFloat(Number(value).toFixed(2))) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Please enter a valid weight"));
  };

  return (
    <Modal
      open={visible}
      title={inventory ? "Edit Inventory" : "Add Inventory"}
      onCancel={() => closePopup(false)}
      footer={null}
      closeIcon={<CloseOutlined />}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
      >
        <Form.Item
          name="variety"
          label="Varieties"
          rules={[{ required: true, message: "Please select a variety!" }]}
        >
          <Select placeholder="Select an account" allowClear>
            {varietiesResponse.map((option) => (
              <Option key={option._id} value={option.name}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="tora50"
          label="Tora 50"
          rules={[{ pattern: /^-?\d+$/, message: "Tora 50 must be a number!" }]}
        >
          <Input placeholder="Enter Tora 50" />
        </Form.Item>
        <Form.Item
          name="tora25"
          label="Tora 25"
          rules={[{ pattern: /^-?\d+$/, message: "Tora 25 must be a number!" }]}
        >
          <Input placeholder="Enter Tora 25" />
        </Form.Item>
        <Form.Item
          name="bori"
          label="Bori"
          rules={[{ pattern: /^-?\d+$/, message: "Bori must be a number!" }]}
        >
          <Input placeholder="Enter Bori" />
        </Form.Item>
        <Form.Item
          name="weight"
          label="Weight"
          rules={[{ validator: validateFloatNumber }]}
        >
          <Input placeholder="Enter Weight" step="0.01" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea placeholder="Enter description" rows={4} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            {inventory ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddInventoryPopup;
