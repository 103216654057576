import { useEffect, useState } from "react";
import moment from "moment-timezone";
import {
  Card,
  Col,
  Row,
  Typography,
  message,
  Button,
  DatePicker,
  Space,
  Empty,
  Modal,
} from "antd";
import {
  PlusOutlined,
  ExportOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import withLayoout from "../../hocs/withLayoout";
import axios from "../../utils/axios";
import AddTransactionPopup from "../../components/AddTransactionPopup";
import { addCommasToAmount } from "../../utils/common";
import FilterNames from "../../components/FilterNames";
import "./styles.scss";
import { API_URL } from "../../constants";
import ls from "../../utils/localstorage";

const { RangePicker } = DatePicker;
const { confirm } = Modal;

function Dashboard() {
  const { Title, Text } = Typography;
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState();
  const [addPopupVisibility, setAddPopupVisibility] = useState(false);
  const [dates, setDates] = useState(null);
  const [singleTransaction, setSingleTransaction] = useState(null);
  const [filtersAdded, setFiltersAdded] = useState();

  const getTransactions = async ({ startDate, endDate }) => {
    try {
      const response = await axios.get(
        `/transaction?startDate=${startDate}&endDate=${endDate}`
      );
      setTransactions(response?.data);
    } catch (error) {
      console.log({ error });
      message.error("Couldn't fetch transactions!");
    }
  };

  useEffect(() => {
    let startDate;
    let endDate;
    if(dates) {
      const [start, end] = dates;
      startDate = start.utc().toISOString();
      endDate = end.utc().toISOString();
    } else {
      startDate = moment.utc().toISOString();
      endDate = moment.utc().toISOString();
    }

    (async () =>
      await getTransactions({
        startDate,
        endDate,
      }))();
    return () => {
      setTransactions(null);
    };
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleTransaction]);

  const handleExport = async () => {
    let startDate;
    let endDate;
    if(dates) {
      const [start, end] = dates;
      startDate = start.utc().toISOString();
      endDate = end.utc().toISOString();
    } else {
      startDate = moment.utc().toISOString();
      endDate = moment.utc().toISOString();
    }
    try {
      const token = ls.get("user")?.accessToken;
      let appendUrl = '';
      if(filtersAdded?.selectedFilters.length) {
        appendUrl = `/transaction?isExport=true&${filtersAdded?.name}=${filtersAdded?.selectedFilters}`;
      } else {
        appendUrl = `/transaction?isExport=true&startDate=${startDate}&endDate=${endDate}`;
      }
      const response = await fetch(
        API_URL + appendUrl,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      );

      const blob = await response.blob();
      console.log({ response, blob });

      if (response?.ok) {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `transactions-${moment()}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        message.success("Transaction Exported Successfully!");
      }
    } catch (error) {
      console.log({ error });
      message.error("Could process not export request");
    }
  };

  const handleClose = async (value) => {
    let startDate;
    let endDate;
    if(dates) {
      const [start, end] = dates;
      startDate = start.utc().toISOString();
      endDate = end.utc().toISOString();
    } else {
      startDate = moment.utc().toISOString();
      endDate = moment.utc().toISOString();
    }

    await getTransactions({
      startDate,
      endDate,
    });
    setAddPopupVisibility(value);
  };

  const handleAddTransaction = () => {
    setAddPopupVisibility((s) => !s);
  };

  const handleRangePickerChange = async (dates) => {
    if (dates) {
      setDates(dates);

      const [start, end] = dates;

      const startDate = start.utc().toISOString();
      const endDate = end.utc().toISOString();

      message.success(`Selected range in UTC:
        From: ${startDate}
        To: ${endDate}`);
      try {
        await getTransactions({ startDate, endDate });
        message.success("Transactions fetched successfully!");
      } catch (error) {
        console.log({ error });
        message.error("Error getting transactions with date range");
      }
    } else {
      message.error("Please select a date range.");
    }
  };

  

  const handleEdit = (transaction) => {
    console.log({ transaction });
    setSingleTransaction(transaction);
    setAddPopupVisibility((s) => !s);
  };
  const handleDelete = (transaction) => {
    confirm({
      title: "Are you sure you want to delete this transaction?",
      icon: <ExclamationCircleOutlined />,
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await axios.delete(`/transaction/${transaction._id}`);
          message.success("Transaction deleted successfully!");
          setSingleTransaction(null);
        } catch (error) {
          message.error("Failed to delete the transaction");
          console.log({ error });
        }
      },
    });
  };
  return (
    <>
      <div className="layout-content top-p">
        <Row gutter={[32, 0]}>
          <div className="button-container">
            <Space direction="horizontal" size={14}>
              <Text strong>Cash in hand: </Text>
              <div> {addCommasToAmount(filteredTransactions?.cashInHand || transactions?.cashInHand) || 0} PKR</div>
              <Text strong>Total Paid : </Text>
              <div> {addCommasToAmount(filteredTransactions?.totalPayed || transactions?.totalPayed) || 0} PKR</div>
              <Text strong>Total Received : </Text>
              <div>
                {addCommasToAmount(filteredTransactions?.totalRecived || transactions?.totalRecived) || 0} PKR
              </div>
              <Button
                onClick={handleAddTransaction}
                type="primary"
                icon={<PlusOutlined />}
              >
                Add
              </Button>
              <Button
                onClick={handleExport}
                type="primary"
                icon={<ExportOutlined />}
                className="mr-5"
              >
                Export
              </Button>
            </Space>
          </div>
          <Col xs={24} sm={24} md={24} lg={24} xl={32} className="mb-32">
            <Card bordered={false} className="criclebox cardbody h-full">
              <div className="project-ant">
                <div className="pl-24">
                  <Title level={1}>Daily Book</Title>
                </div>

                <div>
                  <Row className="mr-10" gutter={[5, 0]}>
                    <div className="ant-filtertabs">
                      <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                        <Space direction="horizontal" size={14}>
                          <Text strong>Filter: </Text>
                          <RangePicker
                            value={dates}
                            onChange={handleRangePickerChange}
                          />
                          <Text strong>Filter:</Text>
                          <FilterNames
                            data={transactions?.transactions}
                            setData={setFilteredTransactions}
                            isTransaction={true}
                            names="accountNames"
                            setFiltersAdded={setFiltersAdded}
                            filtersAdded={filtersAdded}
                          />
                        </Space>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
              <div className="ant-list-box table-responsive">
                <table className="width-100">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Name</th>
                      <th>Amount Debit</th>
                      <th>Amount Credit</th>
                      <th>Invoice Number</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredTransactions
                    ? filteredTransactions?.transactions?.map((d, index) => (
                      <tr key={index}>
                        <td>
                          {moment(d.date).local().format("YYYY-MM-DD HH:mm:ss")}
                        </td>
                        <td>
                          <span className="text-xs font-weight-bold">
                            {d.description}
                          </span>
                        </td>
                        <td>
                          <div>{d.accountName}</div>
                        </td>
                        <td>
                          <div>
                            {d.amount < 0
                              ? addCommasToAmount(Math.abs(d.amount))
                              : 0}{" "}
                            PKR
                          </div>
                        </td>
                        <td>
                          <div>
                            {d.amount > 0 ? addCommasToAmount(d.amount) : 0} PKR
                          </div>
                        </td>
                        <td>
                          <div>{d?.invoiceNumber}</div>
                        </td>
                        <td className="d-flex">
                          <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => handleEdit(d)}
                            style={{ marginRight: 8 }}
                            size="small"
                          />

                          <Button
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => handleDelete(d)}
                            size="small"
                          />
                        </td>
                      </tr>
                    ))
                    : transactions?.transactions?.map((d, index) => (
                      <tr key={index}>
                        <td>
                          {moment(d.date).local().format("YYYY-MM-DD HH:mm:ss")}
                        </td>
                        <td>
                          <span className="text-xs font-weight-bold">
                            {d.description}
                          </span>
                        </td>
                        <td>
                          <div>{d.accountName}</div>
                        </td>
                        <td>
                          <div>
                            {d.amount < 0
                              ? addCommasToAmount(Math.abs(d.amount))
                              : 0}{" "}
                            PKR
                          </div>
                        </td>
                        <td>
                          <div>
                            {d.amount > 0 ? addCommasToAmount(d.amount) : 0} PKR
                          </div>
                        </td>
                        <td>
                          <div>{d?.invoiceNumber}</div>
                        </td>
                        <td className="d-flex">
                          <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => handleEdit(d)}
                            style={{ marginRight: 8 }}
                            size="small"
                          />

                          <Button
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => handleDelete(d)}
                            size="small"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {!transactions?.transactions?.length && (
                  <Empty
                    className="p-3"
                    description={"No Results within the given date range"}
                  />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <AddTransactionPopup
        visible={addPopupVisibility}
        onClose={handleClose}
        closePopup={setAddPopupVisibility}
        transaction={singleTransaction}
        setTransactions={setSingleTransaction}
      />
    </>
  );
}

export default withLayoout(Dashboard);
